import { Card, Typography } from '@ds';
import { ChevronRightIcon } from '@heroicons/react/outline';
import clsx from 'clsx';

interface SuggestedActionCardProps {
  className?: string;
  description: string;
  icon: JSX.Element;
  loading: boolean;
  onClick: () => void;
  title: string;
}

type DescAndTitleFieldVariants =
  | string
  | ((activeQuestionCount: number | undefined) => string)
  | ((lastUpdatePostedDate: string | null | undefined) => string);

export type DehydratedSuggestedActionCardProps = Omit<
  SuggestedActionCardProps,
  'description' | 'loading' | 'onClick' | 'title'
> & {
  description: DescAndTitleFieldVariants;
  title: DescAndTitleFieldVariants;
};

const SuggestedActionLoading = () => (
  <Card
    bordered
    className={clsx(
      `group h-[208px] animate-pulse cursor-pointer bg-white p-4 transition-shadow duration-200 hover:shadow-lg`
    )}
  >
    <div className="flex h-full flex-row content-center justify-center">
      <div className="flex w-full flex-col pr-4">
        <div className="flex h-12 w-12 animate-pulse items-center justify-center rounded-full bg-gray-400"></div>
        <div className="mt-4 flex-1 space-y-6 py-1">
          <div className="h-2 w-11/12 rounded bg-gray-400"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-2 h-2 rounded bg-gray-300"></div>
              <div className="col-span-1 h-2 rounded bg-gray-300"></div>
            </div>
            <div className="h-2 rounded bg-gray-300"></div>
            <div className="h-2 w-2/3 rounded bg-gray-300"></div>
          </div>
        </div>
      </div>
      <div className="my-auto flex content-center items-center justify-center transition-transform group-hover:translate-x-1/4">
        <ChevronRightIcon className="mt-5 h-4 w-4 animate-pulse text-gray-500" />
      </div>
    </div>
  </Card>
);

const SuggestedActionCard: React.FC<SuggestedActionCardProps> = ({
  className,
  description,
  icon,
  loading,
  onClick,
  title,
}) => {
  if (loading) return <SuggestedActionLoading />;

  return (
    <Card
      bordered
      className={clsx(
        `group h-auto cursor-pointer bg-white p-4 transition-shadow duration-200 hover:shadow-lg`,
        className
      )}
      onClick={onClick}
    >
      <div className="flex h-max flex-row content-center justify-between">
        <div className="flex flex-col">
          <div className="bg-amplify-green-50 relative flex h-12 w-12 items-center justify-center rounded-full">
            {/* Render red ping if the title has a number and contains "active question" */}
            {/\d/.test(title) && title.includes('active question') && (
              <>
                <span className="slow-ping absolute right-0 top-0 z-10 h-3 w-3 rounded-full bg-red-500" />
                <span className="absolute right-0 top-0 z-10 h-3 w-3 rounded-full bg-red-500" />
              </>
            )}
            {icon}
          </div>
          <Typography className="mt-4" variant="text-heading-sm">
            {title}
          </Typography>
          <Typography className="mt-2 text-gray-500" variant="text-caption">
            {description}
          </Typography>
        </div>
        <div className="my-auto flex content-center items-center justify-center transition-transform group-hover:translate-x-1/4">
          <ChevronRightIcon className="h-4 w-4 text-gray-900" />
        </div>
      </div>
    </Card>
  );
};

export default SuggestedActionCard;
