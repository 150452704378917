import React from 'react';
import analytics from '@analytics';
import { Typography } from '@ds';
import CompanyOverviewChart from '@/components/analysis/company-overview-chart';
import WelcomeSection from '@/components/analysis/welcome-section';
import SuggestedActions from '@/components/home/suggested-actions';
import Autocomplete from '@/components/investors/search/autocomplete';
import { InvestorsSearchGlobalContextProvider } from '@/components/investors/search/global/context';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';
import { SharedFilterContextProvider } from '@/contexts/shared-filter-context';

const HomeContent = () => {
  const { currentCompanyProfileUser } = useCurrentCompanyProfileUser();
  const userName = currentCompanyProfileUser?.user.firstName;

  return (
    <div className="grid gap-6">
      <WelcomeSection title={`Welcome${userName ? ', ' + userName : ''}`} />

      <div className="px-4 sm:px-0">
        <Typography variant="text-heading-md">Log investor activity</Typography>

        <SharedFilterContextProvider>
          <InvestorsSearchGlobalContextProvider>
            <div
              className="mt-4 min-h-6 w-full"
              onFocus={() => {
                analytics.track('homepage_search_bar_focussed');
              }}
            >
              <Autocomplete fullWidth />
            </div>
          </InvestorsSearchGlobalContextProvider>
        </SharedFilterContextProvider>
      </div>

      <SuggestedActions />
      <CompanyOverviewChart />
    </div>
  );
};

export default HomeContent;
