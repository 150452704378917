import { useEffect } from 'react';
import analytics from '@analytics';
import HomeContent from '@/components/home/home-content';
import Dashboard from '@/components/layouts/dashboard';
import { withCurrentCompanyProfileUserContext } from '@/contexts/current-company-profile-user-context';

const AnalysisPage: React.ComponentType = () => {
  useEffect(() => {
    analytics.track('athena_home_page_viewed');
  }, []);

  return (
    <Dashboard>
      <HomeContent />
    </Dashboard>
  );
};

export default withCurrentCompanyProfileUserContext(AnalysisPage);
