import analytics from '@analytics';
import { AIWriteIcon, Typography } from '@ds';
import { RefreshIcon } from '@heroicons/react/outline';
import {
  ChatIcon,
  RssIcon,
  SpeakerphoneIcon,
  ChartBarIcon,
  VideoCameraIcon,
} from '@heroicons/react/solid';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import {
  useCreateNewMediaUpdateMutation,
  useSuggestedActionsMetadataQuery,
} from '@/apollo/generated';
import SuggestedActionCard, {
  DehydratedSuggestedActionCardProps,
} from '@/components/home/components/suggested-action-card';
import PermissionWrapper from '@/components/layouts/permission-wrapper';
import { useAlert } from '@/contexts/alert-context';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';
import useFeatureEnabled, { FLAGS } from '@/hooks/use-feature-toggles';
import { Permissions } from '@/hooks/use-permission';
import routes from '@/utils/routes';

type SuggestedActionIds =
  | 'action-plan-a-webinar'
  | 'action-generate-ai-summaries'
  | 'action-prepare-announcement'
  | 'action-post-an-update'
  | 'action-answer-questions'
  | 'action-turn-on-automated-distribution'
  | 'action-view-monthly-board-reports';

const answerQuestionsTitleFn = (
  activeQuestionCount: number | undefined
): string =>
  typeof activeQuestionCount === 'number' && activeQuestionCount > 0
    ? `You have ${activeQuestionCount} active question${
        activeQuestionCount > 1 ? 's' : ''
      }`
    : "You don't have any active questions. Nice work!";

const answerQuestionsDescriptionFn = (
  activeQuestionCount: number | undefined
): string =>
  typeof activeQuestionCount === 'number' && activeQuestionCount > 0
    ? 'Addressing questions from investors is best practice and an effective way of building a long term community.'
    : 'If previously answered questions are appropriate, consider making them public to attract more signups.';

const itsBeenAWhile = (lastPostedDate: string): boolean => {
  const lastPosted = dayjs(lastPostedDate);
  const daysSince = dayjs().diff(lastPosted, 'day');

  return daysSince >= 30;
};

const postAnUpdateTitleFn = (
  lastUpdatePostedDate: string | null | undefined
): string =>
  typeof lastUpdatePostedDate === 'string' &&
  itsBeenAWhile(lastUpdatePostedDate)
    ? "It's been a while since your last update"
    : 'Post an update to your investor hub';

const postAnUpdateDescriptionFn = (
  lastUpdatePostedDate: string | null | undefined
): string =>
  typeof lastUpdatePostedDate === 'string' &&
  itsBeenAWhile(lastUpdatePostedDate)
    ? 'Engaging with your community on a regular basis leads to more signups and better investors.'
    : 'Distribute press releases, videos, and webinars via your investor hub to maximise community engagement.';

const allSuggestedActionsProps: Record<
  SuggestedActionIds,
  DehydratedSuggestedActionCardProps
> = {
  'action-answer-questions': {
    description: answerQuestionsDescriptionFn,
    icon: <ChatIcon className="text-amplify-green-700 h-6 w-6" />,
    title: answerQuestionsTitleFn,
  },
  'action-generate-ai-summaries': {
    description:
      'Include automated announcement summaries to maximise your investor engagement',
    icon: <AIWriteIcon className="h-6 w-6" />,
    title: 'Turn on AI-powered summaries',
  },
  'action-plan-a-webinar': {
    description:
      '92% of attendees report benefiting from Q&A sessions, enhancing interaction and trust.',
    icon: <VideoCameraIcon className="text-amplify-green-700 h-6 w-6" />,
    title: 'Plan your first ' + dayjs().year() + ' webinar',
  },
  'action-post-an-update': {
    description: postAnUpdateDescriptionFn,
    icon: <RssIcon className="text-amplify-green-700 h-6 w-6" />,
    title: postAnUpdateTitleFn,
  },
  'action-prepare-announcement': {
    description:
      'Attract up to 9.6x more investors to your hub by adding a video and summary to your next announcement.',
    icon: <SpeakerphoneIcon className="text-amplify-green-700 h-6 w-6" />,
    title: 'Prepare your next announcement',
  },
  'action-turn-on-automated-distribution': {
    description:
      'Convert 2.2x more investors onto your hub by automatically distributing your announcements.',
    icon: <RefreshIcon className="text-amplify-green-700 h-6 w-6" />,
    title: 'Turn on automated distribution',
  },
  'action-view-monthly-board-reports': {
    description:
      'Track key performance indicators and metrics to assess your overall health and progress.',
    icon: <ChartBarIcon className="text-amplify-green-700 h-6 w-6" />,
    title: 'View my monthly board reports',
  },
};

const SuggestedActions = () => {
  const router = useRouter();
  const {
    push,
    query: { marketListingKey },
  } = router;

  const { formatAndShowError } = useAlert();

  const { data, loading } = useSuggestedActionsMetadataQuery({
    fetchPolicy: 'cache-and-network',
  });
  const isWebinarsEnabled = useFeatureEnabled(FLAGS.webinars);

  const suggestedActionsMetadata = data?.suggestedActionsMetadata;
  const isAutomatedDistSwitchedOn =
    !!suggestedActionsMetadata?.isAutomatedDistributionSwitchedOn;

  const hasWebinarThisYear =
    suggestedActionsMetadata?.lastWebinarScheduledDate &&
    dayjs(suggestedActionsMetadata?.lastWebinarScheduledDate).isAfter(
      dayjs().year()
    );
  const AiSummariesOn = !!suggestedActionsMetadata?.aiSummariesEnabled;
  const aiSummaryProps =
    allSuggestedActionsProps['action-generate-ai-summaries'];
  const webinarProps = allSuggestedActionsProps['action-plan-a-webinar'];
  const prepareAnnouncementProps =
    allSuggestedActionsProps['action-prepare-announcement'];
  const postAnUpdateProps = allSuggestedActionsProps['action-post-an-update'];
  const answerQuestionsProps =
    allSuggestedActionsProps['action-answer-questions'];
  const setupAutomatedDistributionProps =
    allSuggestedActionsProps['action-turn-on-automated-distribution'];
  const viewMonthlyBoardReports =
    allSuggestedActionsProps['action-view-monthly-board-reports'];

  const { isPremium } = useCurrentCompanyProfileUser();

  const [createNewMediaUpdate, { loading: createNewMediaUpdateLoading }] =
    useCreateNewMediaUpdateMutation();

  async function onClickCreateNewUpdate() {
    analytics.track('home_suggested_action_clicked', {
      action: 'post_an_update',
    });
    createNewMediaUpdate()
      .then((res) => {
        if (res.data?.createNewMediaUpdate?.id) {
          push({
            pathname: routes.engagement.interactiveMedia.update.edit.href(
              marketListingKey as string,
              res.data.createNewMediaUpdate.id
            ),
            query: { create: true },
          });
        }
      })
      .catch(formatAndShowError);
  }

  // The TypeScript compiler is too dumb to figure this out on it's own I'm afraid
  const postAnUpdateTitleFn = postAnUpdateProps.title as (
    lastUpdatePostedDate: string | null | undefined
  ) => string;
  const postAnUpdateDescriptionFn = postAnUpdateProps.description as (
    lastUpdatePostedDate: string | null | undefined
  ) => string;

  const answerQuestionsTitleFn = answerQuestionsProps.title as (
    activeQuestionCount: number | undefined
  ) => string;
  const answerQuestionsDescriptionFn = answerQuestionsProps.description as (
    activeQuestionCount: number | undefined
  ) => string;

  // Fisher-Yates (also known as Knuth) shuffle algorithm
  function shuffleArray<T>(array: T[]): T[] {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  }

  const trackClickAndPush = (action: string, href: string) => {
    analytics.track('home_suggested_action_clicked', {
      action,
    });
    router.push(href);
  };

  const renderCards = () => {
    const cards = [
      {
        description: postAnUpdateDescriptionFn(
          suggestedActionsMetadata?.lastUpdatePostedDate
        ),
        onClick: () => onClickCreateNewUpdate(),
        permission: Permissions.interactionsMediaUpdatesAdmin,
        permissionKey: 'post-update',
        props: postAnUpdateProps,
        title: postAnUpdateTitleFn(
          suggestedActionsMetadata?.lastUpdatePostedDate
        ),
      },
      {
        description: answerQuestionsDescriptionFn(
          suggestedActionsMetadata?.activeQuestionCount
        ),
        onClick: () =>
          trackClickAndPush(
            'answer_questions',
            routes.engagement.interactiveMedia.manageQuestions.href(
              marketListingKey as string
            )
          ),
        permission: null,
        permissionKey: 'answer_questions',
        props: answerQuestionsProps,
        title: answerQuestionsTitleFn(
          suggestedActionsMetadata?.activeQuestionCount
        ),
      },
    ];
    const conditionalCards = [
      ...cards,
      ...(isWebinarsEnabled && !hasWebinarThisYear
        ? [
            {
              description: webinarProps.description as string,
              onClick: () =>
                trackClickAndPush(
                  'plan_a_webinar',
                  routes.engagement.webinars.href(marketListingKey as string)
                ),
              permission: Permissions.webinarsAdmin,
              permissionKey: 'webinar',
              props: webinarProps,
              title: webinarProps.title as string,
            },
          ]
        : AiSummariesOn
        ? [
            {
              description: prepareAnnouncementProps.description as string,
              onClick: () =>
                trackClickAndPush(
                  'prepare_announcement',
                  routes.engagement.interactiveMedia.prepareAnnouncement.href(
                    marketListingKey as string
                  )
                ),
              permission: Permissions.interactionsMediaAnnouncementsAdmin,
              permissionKey: 'announcement',
              props: prepareAnnouncementProps,
              title: prepareAnnouncementProps.title as string,
            },
          ]
        : [
            {
              description: aiSummaryProps.description as string,
              onClick: () =>
                trackClickAndPush(
                  'ai_summaries',
                  routes.engagement.interactiveMedia.settings.href(
                    marketListingKey as string
                  )
                ),
              permission: null,
              permissionKey: 'ai_summaries',
              props: aiSummaryProps,
              title: aiSummaryProps.title as string,
            },
          ]),
      ...(!isAutomatedDistSwitchedOn && !isPremium
        ? [
            {
              description:
                setupAutomatedDistributionProps.description as string,
              onClick: () =>
                trackClickAndPush(
                  'turn_on_automated_distribution',
                  routes.engagement.campaigns.settings.automatedDistribution.href(
                    marketListingKey as string
                  )
                ),
              permission: Permissions.commsEmailsAdmin,
              permissionKey: 'automated_distribution',
              props: setupAutomatedDistributionProps,
              title: setupAutomatedDistributionProps.title as string,
            },
          ]
        : []),
      ...(isPremium
        ? [
            {
              description: viewMonthlyBoardReports.description as string,
              onClick: () =>
                trackClickAndPush(
                  'view_monthly_board_reports',
                  routes.intelligence.boardReport.href(
                    marketListingKey as string
                  )
                ),
              permission: Permissions.companiesBoardReportsAdmin,
              permissionKey: 'board_reports',
              props: viewMonthlyBoardReports,
              title: viewMonthlyBoardReports.title as string,
            },
          ]
        : []),
    ];

    return shuffleArray(conditionalCards);
  };

  return (
    <div className="px-4 sm:px-0">
      <Typography variant="text-heading-md">Suggested actions</Typography>
      <div
        className={clsx(
          'mt-4 grid gap-4',
          !isAutomatedDistSwitchedOn &&
            isPremium &&
            'grid-cols-1 sm:grid-cols-2 xl:grid-cols-4',
          isAutomatedDistSwitchedOn == isPremium &&
            'grid-cols-1 sm:grid-cols-2 xl:grid-cols-4',
          isAutomatedDistSwitchedOn &&
            !isPremium &&
            'grid-cols-1 lg:grid-cols-3'
        )}
      >
        {renderCards().map((card) => (
          <PermissionWrapper
            key={card.permissionKey}
            name={card.permission ?? undefined}
          >
            <SuggestedActionCard
              className="col-span-1"
              description={card.description}
              icon={card.props.icon}
              loading={loading || createNewMediaUpdateLoading}
              title={card.title}
              onClick={card.onClick}
            />
          </PermissionWrapper>
        ))}
      </div>
    </div>
  );
};

export default SuggestedActions;
